/*
 * Global SASS constants
 * Values which are a given and won't change between projects.
 *
 * NOTE: Keep brackets around group names to define groups for automatic styleguide
 */

/* stylelint-disable comment-empty-line-before, declaration-colon-space-before */


/* [Font weights] */
$fw-thin				: 100; // thin font-weight
$fw-extra-light	: 200; // extra light font-weight
$fw-light				: 300; // light font-weight
$fw-regular			: 400; // regular font-weight
$fw-medium			: 500; // medium font-weight
$fw-semi-bold		: 600; // semi bold font-weight
$fw-bold				: 700; // bold font-weight
$fw-extra-bold	: 800; // extra bold font-weight
$fw-ultra-bold	: 900; // ultra bold font-weight
$fw-normal			: $fw-regular; // normal font-weight, same as regular
/* [/Font weights] */


/* [Ratios] */
$ratio-unison							: 1;
$ratio-diminished-second	: 36/35;
$ratio-minor-second				: 15/14;
$ratio-major-second				: 9/8;
$ratio-diminished-third		: 8/7;
$ratio-augmented-second		: 7/6;
$ratio-minor-third				: 6/5;
$ratio-major-third				: 5/4;
$ratio-perfect-fourth			: 4/3;
$ratio-augmented-fourth		: sqrt(2);
$ratio-perfect-fifth			: 3/2;
$ratio-minor-sixth				: 8/5;
$ratio-golden-ratio				: (1 + sqrt(5)) / 2;
/* [/Ratios] */


/* stylelint-enable */
