﻿.whereweoperateblock {

}

.whereweoperate__wrapper {
    position: relative;
    margin-bottom: 0;

    @include breakpoint(large) {
        min-height: 440px;
    }
}

.whereweoperate__bgimage {
    display: none;

    @include breakpoint(large) {
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 520px 440px;
        display: block;
        height: 100%;
        position: absolute;
        right: -.9375rem;
        top: 0;
        width: 100%;
        z-index: 0;
    }
}
.whereweoperate__image {
    display: block;
    margin-left: auto;
    max-width: 100%;

    @include breakpoint(large) {
        display: none;
    }
}
.whereweoperate__text {
    position: relative;

    @include breakpoint(large) {
        padding-right: 390px;
    }
}
.whereweoperate__numbers {
    padding-left: 1.875rem;
}
.whereweoperate__number {
    font-family: $ff-bold;
    font-size: 60px;
    line-height: 1;
}
.whereweoperate__label {
    font-family: $ff-light;
    font-size: 13px;
    line-height: 1;
    margin-bottom: 1rem;
}

.whereweoperate__number--nl {
    color: $wh-orange;
}
.whereweoperate__number--be {
    color: $wh-blue;
}
.whereweoperate__number--fr {
    color: $wh-green;
}