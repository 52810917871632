﻿.calendarblock {
    background: $calendar-bg-color;
    padding-top: $p;

    h2,
    h2 span {
        color: $calendar-text-color;
        text-align: center;
    }

    .calendar-filter {
        list-style: none;
        margin-left: 0;
        margin-bottom: $m*2;
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
            display: flex;
            border: 1px solid $calendar-border-color;
            background-color: transparent;
            border-radius: 6px;
            color: $calendar-text-color;
            font-size: 0.8125rem;
            line-height: 1;
            margin-left: 5px;
            margin-right: 5px;
            width: auto;

            @include breakpoint(medium) {
                margin-left: 10px;
                margin-right: 10px;
            }

            a {
                color: $calendar-text-color;
                display: block;
                padding: 0.5em;
                text-align: center;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                @include breakpoint(medium) {
                    padding: 0.5em 1em;
                }

                @include breakpoint(large) {
                    padding: 0.5em 1.5em;
                }
            }

            &:hover,
            &.active {
                background-color: $calendar-highlight;
                border-color: $calendar-highlight;
                color: $white;

                a {
                    color: $white;
                }
            }
        }
    }

    .calendar-results {
        position: relative;
        width: 100%;

        &:before {
            content: '';
            display: block;
            width: 100%;
            border-top: 1px solid $white;
            position: absolute;
            top: 50%;
            margin-top: $m*2;
        }

        .calendar-results-slider {
            padding-top: $m*4;

            > div {
                margin: 0 $m;

                h5 {
                    color: $calendar-text-color;
                    font-family: $ff;
                    font-size: 0.75rem;
                    position: relative;
                    margin-top: $m*4;
                }
            }

            .slick-slide {
                width: 165px;
            }

            .calendar-item {
                border-bottom: 0;
                text-align: center;
                cursor: default;

                &:focus {
                    outline: 0;
                }

                > a, div {
                    background: $calendar-highlight;
                    border-radius: 5px;
                    display: inline-block;
                    width: 60px;
                    padding: .675em .5em;

                    span span,
                    strong,
                    sub {
                        display: block;
                    }

                    > span {
                        display: block;
                        background: $calendar-highlight;
                        color: $white;
                        padding: $p/4 0;
                        margin: -$p/2;
                        margin-bottom: 0;
                        font-size: 0.875rem;
                        line-height: 1;
                        font-family: $ff-bold;
                        font-weight: normal;
                    }

                    strong {
                        color: $white;
                        font-family: $ff-bold;
                        font-size: 1.75em;
                        font-weight: bold;
                        line-height: 1;
                    }

                    sub {
                        display: block;
                        font-size: 1em;
                        font-weight: bold;
                        line-height: 1;
                        color: $white;
                        text-transform: lowercase;
                    }
                }
            }
        }

        .slick-prev,
        .slick-next {
            border: 0;
            outline: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 20px;
            height: 35px;
            margin-top: $m;
            color: transparent;

            &:before {
                content: '\e91b';
                font-family: icomoon;
                font-size: 2rem;
                color: $calendar-highlight;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .slick-next {
            left: auto;
            right: 0;

            &:before {
                content: '\e91c';
            }
        }
    }


    @include breakpoint(large) {

        .calendar-filter {
            margin-bottom: $m*4;
        }

        .calendar-results {
            .slick-slide {
                height: auto;
            }

            .slick-prev,
            .slick-next {
                margin-top: -$m;
                outline: 0;
            }
        }
    }
}

.slick-slide, .calendar-item, .calendar-date {
    display: none;

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
