/*** Components: Layout ***/

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: $fs * $factor-s;
}

body {
	&.is-in-debug-mode {
		@include viewport-indicators();
	}
}

.row-flex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.row-fluid {
	@include clearfix;
	max-width: none;
}

.row.expanded .row:not(.expanded) {
    max-width: 75rem;

    h2 {
        text-align: center;
    }

} 

.columns {
	margin-bottom: $m*2;
}

.disable-block-whitespace .columns.teaser {
    margin-bottom: 0;
}

.columns.disable-block-whitespace {
    margin-bottom: 0;
}
