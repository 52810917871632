﻿.search-form, 
.search-head, 
.search-results {
    padding: 0 $p/2;
}

.search-form {
    
    width: 100%;

    input[type="text"] {
        width: 100%;
        display: inline-block;
        border-radius: 0;

        &:focus {
            box-shadow: none;
        }

    }

    input[type="submit"] {
        width: auto;
        display: inline-block;
        background: $brand-color !important;
        color: $white !important;
        transform: skewX(0deg);
        border-radius: 0 !important;
        font-family: $ff !important;
        border: 0;
        padding: $p/4 $p;
    }
    
}

.search-head {
    p {
        margin: 0;
    }
}

.search-results {
    .search-item {
        h3 {
            text-align: left;
        }

        h3, p {
            margin-bottom: $m;
        }

        padding: $p 0;
        margin: 0;
        border-bottom: 1px solid $light-gray;

    }    
}

@include breakpoint(medium) { 

    .search-form {
    
        input[type="text"] {
            width: 50%;          
        }
       
    }

}