/*** Elements: Forms ***/

// form, fieldset, input, select, textarea, etc.
input[type="text"],
select, 
textarea {
    border-radius: 0;

    &:focus {
        box-shadow: none;
    }

}

// mailchimp
#mc_embed_signup {
    font-family: $ff-light;
    font-size: 1rem;
    color: $text-color;
    padding-left: $p;
    padding-right: $p;
    
    @include breakpoint(xlarge) {
        width: 75%;
        padding-left: 0;
        padding-right: 0;
    }

    h2 {
        font-size: 2.1875rem !important;
    }

    .indicates-required {
        font-family: $ff-light;
        text-align: right;
        margin-right: 0;

        .asterisk {
            color: $red;
            font-size: 1rem;
            line-height: 1;
            font-weight: normal !important;
        }

    }

    .mc-field-group {
        position: relative;
        font-family: $ff-light;
        font-size: 1rem;
        color: $text-color;
        padding-bottom: 0 !important;
        margin-bottom: $m !important;
        
        label {
            font-weight: 700 !important;
            font-family: $ff-light !important;
            font-size: 1rem;
            color: $text-color;
        }
        
        select {
            border: 1px solid $gray;
        }

        select,
        input {
            padding: 8px 0 !important;
            border-radius: 5px !important;
            text-indent: 2% !important;
            border: 1px solid $gray;
            margin-bottom: 0 !important;
            border-radius: 0 !important;
        }
        
        select:focus,
        input:focus {
            border-color: $brand-color !important;
            box-shadow: 0 0 0 $brand-color !important;
            outline: 0 none !important;
        }

        input[type="radio"],
        input[type="checkbox"] {
            display: inline-block;
            width: auto;
            margin-bottom: 0 !important;

        }

        .asterisk {
            position: absolute;
            top: 5px;
            right: 0 !important;
            color: $red !important;
            font-family: $ff-light;
            font-size: 1rem;
            font-weight: normal !important;
            line-height: 1;
        }

        ul {
            list-style: none;
            margin: 0 !important;
            padding: 0 !important;

            li {
                padding: 0 !important;

                label {
                   font-weight: normal !important;
                }
            }

        }

    }
    
    input.mce_inline_error,
    input.mce_inline_error:focus {
        border-color: $red !important;
    }

    div.mce_inline_error {
        margin: 5px 0 !important;
        background-color: $red !important;
        font-weight: normal !important;
        border-radius: 0 !important;
        color: $white !important;
    }

    .button {
        background: $brand-color !important;
        color: $white !important;
        transform: skewX(0deg);
        font-family: $ff !important;
    }

}