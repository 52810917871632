﻿.country-nl {
    h1, h2 {
        color: $wh-orange;
    }

    .page-content, .page-content-main {
        a {
            color: $wh-yellow;
        }
    }

    .overview-item {
        h3 {
            color: $wh-yellow;
        }

        .button--overview-item {
            background-color: $wh-orange;
            color: $white;

            &:active,
            &:focus,
            &:hover {
                background: darken($wh-orange, 10);
            }
        }
    }

    .whereweoperate__text {
        h3 {
            color: $wh-yellow;
        }
    }

    .sidebar {
        .content-bg-first {
            background-color: $wh-yellow;
        }

        .content-bg-second {
            background-color: $wh-orange;
        }
    }

    .editorialblock .country-nl .small-12.columns {
        strong {
            color: $wh-orange;
            font-size: 20px;
        }

        a {
            color: $text-color;
        }
    }

    .page-type {
        color: $wh-yellow;

        &:after {
            background: $wh-yellow;
        }
    }

    .page--people & {
        .page-content {
            h2, h3, h4, h5, h6 {
                color: $wh-orange;
            }
        }
    }
}

.country-be {
    h1, h2 {
        color: $wh-blue;
    }

    .page-content, .page-content-main {
        a {
            color: $wh-darkblue;
        }
    }

    .overview-item {
        h3 {
            color: $wh-darkblue;
        }

        .button--overview-item {
            background-color: $wh-darkblue;
            color: $white;

            &:active,
            &:focus,
            &:hover {
                background: darken($wh-darkblue, 10);
            }
        }
    }

    .whereweoperate__text {
        h3 {
            color: $wh-darkblue;
        }
    }

    .sidebar {
        .content-bg-first {
            background-color: $wh-darkblue;
        }

        .content-bg-second {
            background-color: $wh-blue;
        }
    }

    .editorialblock .country-be .small-12.columns {
        strong {
            color: $wh-blue;
            font-size: 20px;
        }

        a {
            color: $text-color;
        }
    }

    .page-type {
        color: $wh-darkblue;

        &:after {
            background: $wh-darkblue;
        }
    }

    .page--people & {
        .page-content {
            h2, h3, h4, h5, h6 {
                color: $wh-blue;
            }
        }
    }
}

.country-fr {
    h1, h2 {
        color: $wh-green;
    }

    .page-content, .page-content-main {
        a {
            color: $wh-darkgreen;
        }
    }

    .overview-item {
        h3 {
            color: $wh-darkgreen;
        }

        .button--overview-item {
            background-color: $wh-green;
            color: $white;

            &:active,
            &:focus,
            &:hover {
                background: darken($wh-green, 10);
            }
        }
    }

    .whereweoperate__text {
        h3 {
            color: $wh-darkgreen;
        }
    }


    .sidebar {
        .content-bg-first {
            background-color: $wh-darkgreen;
        }

        .content-bg-second {
            background-color: $wh-green;
        }
    }

    .editorialblock .country-fr .small-12.columns {
        strong {
            color: $wh-green;
            font-size: 20px;
        }

        a {
            color: $text-color;
        }
    }

    .page-type {
        color: $wh-darkgreen;

        &:after {
            background: $wh-darkgreen;
        }
    }

    .page--people & {
        .page-content {
            h2, h3, h4, h5, h6 {
                color: $wh-green;
            }
        }
    }
}

.holding-nv {
    h1, h2 {
        color: $wh-green;
    }

    .page-content, .page-content-main {
        a {
            color: $wh-darkgreen;
        }
    }

    .overview-item {
        h3 {
            color: $wh-darkgreen;
        }

        .button--overview-item {
            background-color: $wh-darkgreen;
            color: $white;

            &:active,
            &:focus,
            &:hover {
                background: darken($wh-darkgreen, 10);
            }
        }
    }

    .whereweoperate__text {
        h3 {
            color: $wh-darkgreen;
        }
    }


    .sidebar {
        .content-bg-first {
            background-color: $wh-darkgreen;
        }

        .content-bg-second {
            background-color: $wh-green;
        }
    }

    .editorialblock .country-fr .small-12.columns {
        strong {
            color: $wh-green;
            font-size: 20px;
        }

        a {
            color: $text-color;
        }
    }

    .page-type {
        color: $wh-darkgreen;

        &:after {
            background: $wh-darkgreen;
        }
    }

    .page--people & {
        .page-content {
            h2, h3, h4, h5, h6 {
                color: $wh-green;
            }
        }
    }
}

.holding-be {
    h1, h2 {
        color: $wh-blue;
    }

    .page-content, .page-content-main {
        a {
            color: $wh-darkblue;
        }
    }

    .overview-item {
        h3 {
            color: $wh-darkblue;
        }

        .button--overview-item {
            background-color: $wh-darkblue;
            color: $white;

            &:active,
            &:focus,
            &:hover {
                background: darken($wh-darkblue, 10);
            }
        }
    }

    .whereweoperate__text {
        h3 {
            color: $wh-darkblue;
        }
    }


    .sidebar {
        .content-bg-first {
            background-color: $wh-darkblue;
        }

        .content-bg-second {
            background-color: $wh-blue;
        }
    }

    .editorialblock .country-fr .small-12.columns {
        strong {
            color: $wh-blue;
            font-size: 20px;
        }

        a {
            color: $text-color;
        }
    }

    .page-type {
        color: $wh-darkblue;

        &:after {
            background: $wh-darkblue;
        }
    }

    .page--people & {
        .page-content {
            h2, h3, h4, h5, h6 {
                color: $wh-blue;
            }
        }
    }
}