/*** Elements: Text ***/

a {
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: $m/2;
    line-height: $lh-h;
    color: $brand-heading-color;
    font-family: $ff-bold;
    
    font-style: normal;
    font-weight: normal;
}

/* Extra classes for Epi TinyMCE */
h2.purplethin {
    font-family: $ff-light;
    color: $wh-purple;
}

h5.purple {
    color: $wh-purple;
}
/* end */

h1 {
    font-size: 2rem;
    margin: 0 0 $m 0;
}

h2 {
    font-size: 2rem;
    margin: 0 0 $m 0;
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1.25rem;
}

body {
    color: $text-color;
    line-height: 2;
    
    font-style: normal;
    font-weight: normal;
}

p {
    margin-bottom: 2rem;
}

.lead {
    font-size: 1rem;
    color: $black;
    text-align: center;
}

.tooltip {
    font-family: $ff;
    font-size: .8125rem;
    line-height: 1.4em;
    width: 150px !important;
    max-width: 150px !important;
    padding: 2rem 1rem 1rem 1rem;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    color: $text-color;

    @include breakpoint(320px) {
        &.top {
            padding: .75rem 1rem;
        }
    }

    &:before {
        display: none !important;
    }

    &:after {
        bottom: -20px;
        border-bottom: 20px solid $white;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        content: '';
        display: block;
        height: 0;
        left: calc(50% - 8px);
        position: absolute;
        width: 0;
    }

    span {
        color: $calendar-tooltip-text;
        display: block;
        font-family: $ff-bold;
    }
}

.breadcrumb {
    margin: 0;

    a {
        color: $text-color;
    }
}
