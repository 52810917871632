﻿.shareblock {
    ul {
        list-style: none;
        margin: 0;

        li {
            display: inline-block;
            padding: 0 4px;

            @include breakpoint(medium) {
                padding: 0 10px;
            }

            @include breakpoint(large) {
                padding: 0 3px;
            }

            @include breakpoint(xlarge) {
                padding: 0 13px;
            }

            &:first-child {
                padding-left: 0;
            }

            a {
                display: block;
                width: 50px;
                height: 50px;
                border: 1px solid $white;
                border-radius: 25px;
                text-align: left;
                position: relative;
                text-decoration: none !important;
                
                &:hover {
                    text-decoration: none;
                }

                &:before {
                    position: absolute;
                    top: 0;
                    left: 1px;
                    width: auto;
                    font-family: icomoon;
                    font-size: 3rem;
                    line-height: 50px;
                    display: inline-block;
                }

                &.share-twitter {
                    &:before {
                        content: '\e915';
                    }
                }

                &.share-facebook {
                    &:before {
                        content: '\e901';
                    }
                }

                 &.share-linkedin {
                    &:before {
                        content: '\e908';
                    }
                }

                &.share-mail {
                    &:before {
                        content: '\e90a';
                        top: -1px;
                        left: 0;
                    }
                }


            }

        }

    }
}