﻿.portfolioblock {
    padding-top: 2.25em;
    padding-bottom: 2em;

    h2 {
        font-size: 2em;
        margin-bottom: 0.5em;
        text-align: center;
    }

    .portfolio-items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;

        > div {
            padding: 0 30px;
            @include breakpoint(medium) {
                min-width: 220px;

                .button {
                    min-width: 160px;
                }
            }
        }

        a {
            text-transform: uppercase;

            figure {
                align-items: center;
                display: flex;
                justify-content: center;
                height: 130px;
                margin: 0 auto $m;

                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            strong {
                font-family: $ff-bold;
                font-weight: normal;
            }

            &:hover {
                color: $brand-color;
            }
        }

        .slick-prev,
        .slick-next {
            border: 0;
            outline: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 20px;
            height: 35px;
            margin-top: $m;
            color: transparent;

            &:before {
                content: '\e91b';
                font-family: icomoon;
                font-size: 2rem;
                color: #B3B1B1;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .slick-next {
            left: auto;
            right: 0;

            &:before {
                content: '\e91c';
            }
        }
    }
}

.portfolio__text {
    color: $text-color;
}