/*** Components: Blocks ***/

// hero, article, headline, visual with caption, etc.



.editorialblock {

    margin-top: 2em;
   
}

