﻿.page {
    overflow: hidden;

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: $m;
    }

    .page-type {
        position: relative;
        padding-bottom: $p/3;
        margin-bottom: $m/2;
        color: $wh-green;

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            content: '';
            background: $wh-green;
            width: 60px;
            height: 3px;
        }
    }

    .page-date {
        margin-bottom: $m*2;
        color: $medium-gray;
        font-size: 1rem;
        font-family: $ff;
    }

    .lead {
        text-align: left;
    }

    .page-content {

        @include breakpoint(medium) {
            .text-center.page-content-main {
                width: 66.6667%;
                margin-left: 16.6667%;
            }
        }
    }

    .sidebar {
        position: relative;
        height: auto;

        .people {
            .imagewithtextfile {
                float: none !important;

                .imageblock {
                    padding: 0;
                    margin: -1.875rem;
                    overflow: hidden;
                    height: 500px;

                    .columns {
                        border: 0;
                        height: 500px;
                    }
                }
            }
        }

        .content-bg-first,
        .content-bg-second {
            overflow: hidden;
            min-height: 1px;
            padding: $p;
            color: $white;

            .row {
                padding-bottom: 0;
            }

            .imagefile {
                float: none !important;

                .imageblock {
                    padding: 0;
                    margin: 1.875rem -3.75rem 1.875rem -6.5625rem;
                    overflow: hidden;
                    height: 500px;

                    .columns {
                        border: 0;
                        height: 500px;
                    }
                }
            }

            > div > .imagefile > .imageblock {
                margin: -1.875rem;
            }

            h2, h3 {
                font-size: 1.5rem;
                color: $white;

                span {
                    color: $white;
                }
            }

            a {
                color: $white;
                text-decoration: underline;

                &:hover {
                    @include color-opacity($white, 0.7);
                }
            }
        }

        .content-bg-first {
            background: $sidebar-top;
        }

        .content-bg-second {
            background: $sidebar-bottom;
        }
    }
}
