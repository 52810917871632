/*============================
    VARS
============================*/

$slider-color-checked: $wh-green;
$slider-color-unchecked: #B9B9B9;

$slider-width: 40px;
$slider-height: $slider-width / 2;

$slider-border-rad: 10px;

$slider-dot-size: $slider-height - ($slider-height / 20);
$slider-color-dot: #fff;

/*============================
    STYLE
============================*/

.slider {
  position: relative;

  &--disabled {
      .slider__input {
          &:checked {
              & + .slider__label {
                  &::before {
                      background-color: $slider-color-unchecked;
                  }
              } 
          }
      }
  }

  &__input {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;

    width: $slider-width;
    height: $slider-height;
    margin: 1px 0;
    opacity: 0;

    cursor: pointer;

    &:checked + .slider__label {
      &:before {
        content: '';

        padding-left: 6px;

        background-color: $slider-color-checked;
      }

      &:after {
        left: $slider-height + 1;
      }
    }
  }

  &__label {
    position: relative;

    padding-left: $slider-width + 6;

    &:before,
    &:after {
      position: absolute;

      border-radius: $slider-border-rad;
      transition: background-color 0.3s, left 0.3s;
    }

    &:before {
      content: '';

      top: 0px;
      left: 0px;

      height: $slider-height;
      width: $slider-width;

      background-color: $slider-color-unchecked;
    }

    &:after {
      content: '';

      top: 0;
      left: 1px;

      height: $slider-dot-size;
      width: $slider-dot-size;

      background: $slider-color-dot;
    }
  }
}