/*** Elements: Buttons ***/

.button {
    border: 0;
    background-color: $brand-button-color;
    border-radius: 6px;
    color: $white;
    padding: $p/4 $p;
    position: relative;

    &:active,
    &:focus,
    &:hover {
        background: darken($brand-button-color, 10);
    }
}

.button--nl {
    background-color: $wh-orange;

    &:active,
    &:focus,
    &:hover {
        background: darken($wh-orange, 10);
    }
}

.button--be {
    background-color: $wh-darkblue;

    &:active,
    &:focus,
    &:hover {
        background: darken($wh-darkblue, 10);
    }
}

.button--fr {
    background-color: $wh-green;

    &:active,
    &:focus,
    &:hover {
        background: darken($wh-green, 10);
    }
}

.button-outlined {
    border: 1px solid $white;
    background-color: transparent;
    color: $white;

    &:active,
    &:focus,
    &:hover {
        background: $white;
        color: $brand-color;
    }
}

.button-more {
    background-color: $tertiairy-color;
    color: $white;

    &:active,
    &:focus,
    &:hover {
        background: darken($tertiairy-color, 10);
        color: $white;
    }
}

.button-white {
    background-color: $white;
    color: $text-color;

    &:active,
    &:focus,
    &:hover {
        background-color: #f1f1f1;
        color: $text-color;
    }
}

.button-cookie {
    padding-left: $p*1.7;

    &:before {
        display: inline-block;
        font-family: icomoon;
        font-variant: normal;
        font-size: 1.5rem;
        line-height: 1;
        transform: skewX(-160deg);
        position: absolute;
        top: 0;
        left: $p/2;
    }
}

.button-agree {
    border-color: $button-agree-color;
    color: $button-agree-color;

    &:active,
    &:focus,
    &:hover {
        background: $button-agree-color;
        color: $white;
    }
}

.button-disagree {
    border-color: $button-disagree-color;
    color: $button-disagree-color;

    &:active,
    &:focus,
    &:hover {
        background: $button-disagree-color;
        color: $white;
    }
}

.link-download, 
.link-more {
    padding-right: $p;
    position: relative;

    &:after {
        display: inline-block;
        font-family: icomoon;
	    font-variant: normal;
        font-size: 1.2rem;
        line-height: 1;
        position: absolute;
        top: -2px;
        right: 0;
        content: '\e91a';
    }

}


.link-download-left {
    
      &:before {
         display: inline-block;
        font-family: icomoon;
	    font-variant: normal;
        font-size: 1.2rem;
        padding: 0 5px;
        line-height: 1;
        content: '\e91a';
    }
}


.link-download {
    &:after {
        content: '\e91a';
    }
}

.link-more {
    &:after {
        content: '\e917';
        top: 0;
    }
}
