﻿.imagegalleryblock {
    padding: 0 !important;

    .imagegallery  {
         .columns {
             margin-bottom: 0;
             float: left !important;
         }
     }

    .imagefile {
        .imageblock {
            border: 5px solid #fff;
            border-top: 0;
        }

        .imageblock-noborder {
            border: 0;
        }

    }  

}

.imagefile, .imagewithtextfile {
    margin: 0;
    padding: 0;
    float: left !important;

    .imageblock {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        height: 300px;

        @include breakpoint(large) {
            height: 260px;
        }

        @include breakpoint(xlarge) {
            height: 300px;
        }

        .row {
            height: 100%;
            padding: 0;

            .columns {
                padding: 0;
            }
        }

        .row--center {
            align-items: center;
            display: flex;
            justify-content: center;
            text-align: center;
        }

        &.doubleheight {
            @include breakpoint(medium) {
                height: 600px;
            }
        }
    }

    &.large-3,
    &.large-6 {
        .imageblock {
            border: 5px solid #fff;
        }
    }
}
.imageblock__text {
    color: #fff;
    text-align: center;

    p {
        color: #fff;
        font-family: $ff-bold;
        font-size: 2em;
        line-height: 1.2;
        margin-bottom: 10px;
    }
}
.imageblock--dark {
    position: relative;

    &:before {
        background: rgba(84,83,83,0.62);
        bottom: 0;
        content: '';
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    > * {
        position: relative;
        z-index: 1;
    }
}