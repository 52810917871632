/*
 * Global SASS variables with default values
 *
 * NOTE: Keep brackets around group names to define groups for automatic styleguide
 *
 * The em unit and unitless are relative to an element's font-size, so it visually matches.
 * The rem unit is relative to the root's font-size, keeps grid aligment.
 */

/* stylelint-disable comment-empty-line-before, declaration-colon-space-before */


/* [Gray scales] */
$white				: #fff;
$light-gray         : #dfddde;
$medium-gray        : #b9b9b9;
$gray 				: #737373;
$dark-gray 			: #5f6564;
$black				: #000;
$rgb-white 			: rgb(255, 255, 255);
$rgb-semi-black	    : rgba(0, 0, 0, 0.5);
$rgb-black 			: rgb(0, 0, 0);
/* [/Gray scales] */

/* [Colors] */
$wh-purple          : #9B557D;
$wh-pink            : #D25A82;
$wh-orange          : #EB966E;
$wh-yellow          : #F0A023;
$wh-green           : #8CC89B;
$wh-darkgreen       : #199687;
$wh-blue            : #69B4BE;
$wh-darkblue        : #198CB9;
$wh-lightgrey       : #9BA0AA;
$wh-grey            : #69787D;
$wh-darkgrey        : #32373C;
/* [/Colors] */

$red: #ff0000;


/* [Color mapping] */
$brand-color        : $wh-purple;
$primary-color      : $wh-darkgreen;
$secondary-color    : $wh-blue;
$tertiairy-color    : $wh-pink; 
$success-color 	    : $wh-purple;
$warning-color 	    : $red;
$alert-color 		: $red;
$button-agree-color : $wh-purple;
$button-disagree-color: $wh-blue;

$text-color			: #737373;
$link-color			: $primary-color;
$border-color		: $medium-gray;
$disabled-color	    : $light-gray;
$brand-button-color	: $primary-color;

$brand-heading-color: $wh-green;

$sidebar-top: $wh-yellow;
$sidebar-bottom: $wh-orange;

$doubleteaser-title: $white; 

$overview-label: $wh-blue;
$overview-date: $wh-blue;

$calendar-highlight: #9A557A;
$calendar-bg-color: $light-gray;
$calendar-border-color: #979797;
$calendar-text-color: $text-color;
$calendar-tooltip-text: $wh-purple;

/* [/Color mapping] */
/* [General defaults] */
$m : 1em; 					        // default margin
$p							: 1.875rem; 			            // default padding
$bw							: 0.25em; 			            // default border width
$b							: solid 0.25em $primary-color;  // default border
$br							: 0.5em; 				        // default border radius
$d							: 0.25s; 				        // default transition duration
$e							: ease-in-out; 	                // default transition timing function
$btn-height			        : 2.5rem;				        // default button height

$fs							: 100%; 				        // default base font-size
$fs-s						: 0.875rem; 				    // small paragraph font-size
$fs-m						: 1.25rem; 			            // medium paragraph font-size
$fs-l						: 1.5rem; 			            // large paragraph font-size

$lh							: 1.5; 					        // default line-height
$lh-s						: 1; 						    // small line-height
$lh-m						: $lh; 					        // medium line-height
$lh-l						: 2; 						    // large line-height

$ff                         : 'Proxima Nova Reg', 'Calibri', Helvetica, Arial, sans-serif; // default font-family for body
$ff-bold                    : 'Proxima Nova Bd', 'Calibri', Helvetica, Arial, sans-serif; // default font-family for body
$ff-light                   : 'Proxima Nova Thi', 'Calibri', Helvetica, Arial, sans-serif; // default font-family for body
/* [/General defaults] */

/* [Headings] */
$heading-ratio 	: $ratio-perfect-fourth;

$fs-h-xs		: 1rem;                         // extra small heading font-size (h6)
$fs-h-s			: $fs-h-xs 	* $heading-ratio;   // small heading font-size (h5)
$fs-h-m			: $fs-h-s 	* $heading-ratio;   // medium heading font-size (h4)
$fs-h-l			: $fs-h-m 	* $heading-ratio;   // large heading font-size (h3)
$fs-h-xl		: $fs-h-l 	* $heading-ratio;   // extra large heading font-size (h2)
$fs-h-xxl 	    : $fs-h-xl 	* $heading-ratio;   // extra extra large heading font-size (h1)

$h-fluid-ratio	: $ratio-minor-third;

$fs-hf-xs		: 5vw;                          // extra small heading font-size (h6)
$fs-hf-s		: $fs-hf-xs * $h-fluid-ratio;   // small heading font-size (h5)
$fs-hf-m		: $fs-hf-s 	* $h-fluid-ratio;   // medium heading font-size (h4)
$fs-hf-l		: $fs-hf-m 	* $h-fluid-ratio;   // large heading font-size (h3)
$fs-hf-xl		: $fs-hf-l 	* $h-fluid-ratio;   // extra large heading font-size (h2)
$fs-hf-xxl 	: $fs-hf-xl * $h-fluid-ratio;       // extra extra large heading font-size (h1)

$lh-h						: $lh-s; // default line-height for headings

$ff-h						: $ff; // font-family for headings
/* [/Headings] */


/* [Scaling factors] */
$scaling-ratio 	: $ratio-minor-second;

$factor-s 	: 1;
$factor-m 	: $factor-s 	* $scaling-ratio;
$factor-l 	: $factor-m 	* $scaling-ratio;
$factor-xl 	: $factor-l 	* $scaling-ratio;
$factor-xxl : $factor-xl 	* $scaling-ratio;
/* [/Scaling factors] */

/* [Others] */
$footer-bg:    '/Static/images/footer-bg.png';

/* [/Others] */

/* stylelint-enable */
 