﻿.filterablepageoverviewblock,
.generaloverviewblock {

    h2 {
        color: $wh-green;
        text-align: center;
        margin-bottom: 3rem;
    }

    .button {
        margin-top: $m*2;

        @include breakpoint(large) {
            margin-top: 0;
        }
    }

    .row > .columns {
        padding: 0;
    }

    .overview-filter {
        margin-bottom: $m;
        text-align: center;

        select {
            width: auto;
            min-width: 250px;
            display: inline-block;
            height: auto;
        }
    }

    ul {
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            font-size: 0.75rem;
            color: $black;
            overflow: hidden;
            display: flex;
            margin-bottom: $m;
            min-height: 200px;
            position: relative;
            margin-left: -1px;
        }
    }

    .row-wrapper {
        margin-left: 0;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;

        .block {
            color: $black;
            overflow: hidden;
            display: flex;
            margin-bottom: $m;
            position: relative;
            margin-left: -1px;
        }
    }

    .overview-item {
        display: flex;
        font-size: 16px;
        flex-direction: column;
        position: relative;
        width: 100%;

        .overview-image {
            width: 60%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        h3, h4, h5 {
            font-size: 1rem;
            text-align: left;
        }

        h3 {
            color: $primary-color;
            line-height: 1.5em;
            margin-bottom: 1rem;
        }

        h4, h5 {
            color: $text-color;
            margin-bottom: 5px;
        }

        h4 {
            padding-left: 10px;
            display: inline-block;
            font-family: $ff;
            font-weight: normal;
        }

        h5 {
            font-weight: bold;
        }

        p {
            margin-bottom: 1rem;
            line-height: 1.5em;
        }

        .overview-text {
            align-items: flex-start;
            background: $white;
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-between;
            padding: $p/2;
        }

        .overview-inner {
            flex: 1;
        }

        img {
            width: 100%;
        }
    }

    .overview-subtitle {
        display: flex;
        flex-direction: row;
    }

    @include breakpoint(large) {

        .row > .columns {
            padding-left: 0.9375rem;
            padding-right: 0.9375rem;
        }

        .overview-filter {
            margin-bottom: $m*4;
        }

        ul {
            margin-left: -$m;
            margin-right: -$m;

            li {
                margin-bottom: $m*4;
                min-height: 381px;
                font-size: 0.875rem;
            }
        }

        .row-wrapper {
            margin-left: -$m;
            margin-right: -$m;

            .block {
                margin-bottom: $m*2;
            }
        }

        .overview-item {

            h3, h4, h5 {
                font-size: 1.125rem;
            }

            h3 {
                line-height: 1.3;
                margin-bottom: 1em;
            }

            p {
                margin-bottom: 2rem;
            }
        }
    }
}

.generaloverviewblock {
    padding-top: $p*2;
}

.block {
    &.block--bg {
        background-color: rgba(115, 115, 115, .1);
        position: relative;

        &:before, &:after {
            background-color: rgba(115, 115, 115, .1);
            content: '';
            display: block;
            height: 100%;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 2000px;
        }

        &:before {
            right: 100%;
        }

        &:after {
            left: 100%;
        }
    }
}

.peoplepage {
    .overview-item {
        h3 {
            margin-bottom: .5rem;
        }
        p {
            margin-bottom: 1rem;
        }
    }
}