/* navigation */

.top-bar {
    width: 100%;
    height: 50px;
    position: fixed;
    z-index: 150;

    @include breakpoint(xlarge) {
        max-width: 73rem;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);

        &:before, &:after {
            content: '';
            width: 1000px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100%;
            background: $white;
        }

        &:after {
            right: auto;
            left: 100%;
        }
    }


    .top-bar-left,
    .top-bar-right {
        padding: 0;
        margin: 0;
    }

    .top-bar-center {
        text-align: center;
        height: 50px;
        width: 100%;
        background: $white;
        border-bottom: 1px solid $light-gray;

        .logo {
            text-decoration: none;
        }

        .logo img {
            width: auto;
            margin: 15px 0 0;
            height: 20px;

            &:first-child {
                display: none;
            }

            &:only-child {
                display: inline-block !important;
            }
        }
    }

    .top-bar-left {
        width: 75%;
        margin-top: -51px;
        background: $white;
        border-bottom: 1px solid $light-gray;
        display: flex;
        flex-direction: column;

        .toggle-menu {
            display: block;
            width: 54px;
            height: 50px;
            cursor: pointer;
            order: 0;
            padding: 15px;

            span {
                display: block;
                width: 24px;
                height: 4px;
                border-radius: 2px;
                background: $text-color;
                margin-bottom: 4px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        > .menu {
            width: 100%;
            background: $white;
            margin: 0;
            line-height: 1;
            display: none;
            border: 1px solid $light-gray;
            border-left: 0;
            border-top: 0;
            order: 2;

            > li {
                width: 100%;
                padding: $p/4;
                list-style: none;
                position: relative;
                font-size: 0.9375rem;
                line-height: 1;
                border-top: 1px solid $light-gray;

                &:first-child {
                    border-top: 0;
                }

                > a {
                    font-family: $ff-bold;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                a {
                    color: $black;
                    padding: $p/4 $p/2;
                    display: inherit;
                }

                &.active > a {
                    text-decoration: underline;
                }

                span {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 100;
                    display: block;
                    width: 50px;
                    height: 44px;
                    cursor: pointer;
                }

                &.is-dropdown-submenu-parent,
                &.is-dropdown-submenu-parent .is-dropdown-submenu-parent {
                    position: relative;


                    > a:after {
                        content: '\e916';
                        font-family: 'icomoon';
                        font-size: 0.875em;
                        display: block;
                        width: 20px;
                        height: 20px;
                        margin: 0 $m 0 0;
                        position: absolute;
                        right: 0;
                        top: 16px;
                        color: $gray;
                    }
                }

                &.is-dropdown-submenu-parent .is-dropdown-submenu-parent {
                    > a:after {
                        margin-right: .5em;
                        top: 8px;
                    }
                }

                .is-dropdown-submenu {
                    width: 100%;
                    list-style: none;
                    padding: 0 0 0 $p;
                    margin-left: 0;
                    display: none;

                    .is-submenu-item {
                        position: relative;
                        display: block;
                        margin: 0;

                        > a {
                            background-color: $white;
                            transition: all .25s ease-in-out;
                        }

                        &:hover > a {
                            background-color: $wh-green;
                            color: $white;
                        }

                        .is-dropdown-submenu {
                            display: none;
                        }

                        .is-submenu-item {

                            a {
                                color: $dark-gray;
                                font-size: 0.875rem;
                                text-transform: none;

                                &:hover {
                                    background-color: transparent;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            .is-dropdown-submenu-open .is-dropdown-submenu,
            .is-dropdown-submenu-open .is-submenu-item.is-dropdown-submenu-open .is-dropdown-submenu {
                display: block;
                padding-top: $p/4;
            }

            .header-button {
                margin-left: 0px !important;
                float: right !important;
            }

            .header-button a {
                padding: 8px 15px;
                text-decoration: none !important;
                background: #FFF;
                color: #30383B;
                border-radius: 6px;
                transition: transform .2s linear;
            }

            .header-button a:hover {
                color: #FFF;
                background: transparent;
                border-color: #FFF;
                box-shadow: 0 0 0 1px #FFF;
            }

            &.is-dropdown-open {
                display: block;

                .header-button a:hover {
                    color: #30383B;
                    background: none;
                    border-color: none;
                    box-shadow: none;
                    text-decoration: underline !important;
                }

                & + .nav-language {
                    border-right: 1px solid $light-gray;
                    display: block;
                    margin: 0;
                    padding: $p/4 $p/2 0;
                    text-align: center;
                }
            }
        }
        /* search */
        .searchbox {
            display: none;
            padding: 1.4rem 0px 0 24px;
            position: relative;
            width: 100%;
            border-right: 1px solid $light-gray;
            order: 1;

            a {
                display: none;
            }

            .searchbox-dropdown {
                display: inline-block;
                background: $white;
                width: 100%;
                position: relative;

                input[type="text"] {
                    border: 1px solid $light-gray;
                    box-shadow: none;
                }

                input[type="submit"] {
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    right: 0;
                    height: 37px;
                    width: 37px;
                    border: 0;
                    background: transparent;
                    color: transparent;
                }

                .glyphicon-search {
                    &:before {
                        position: absolute;
                        z-index: 1;
                        top: 3px;
                        right: 0;
                        content: '\e91d';
                        font-family: icomoon;
                        font-weight: bold;
                        height: 30px;
                        width: 30px;
                        color: $text-color;
                    }
                }
            }
        }
    }

    .top-bar-left-scroll {
        height: 100vh;
        overflow: scroll;
    }
}

/* language navigation */ 
.nav-language {
    font-size: 0.9375rem;
    line-height: 1;
    display: none;
    order: 3;
    
    li {
        list-style: none;
        display: inline-block;
        text-transform: uppercase;
        font-size: 0.9375rem;
        line-height: 1;
        padding-bottom: $p/4;

        a {
            color: inherit;            
        }

        span {
            text-decoration: underline;
        }

        &:before {
            content: '|';
        }

        &:first-child:before {
            display: none;
        }
    }
}

@include breakpoint(xlarge) {

    .top-bar {
        height: 65px;
        background: linear-gradient(to bottom, rgba(95,101,100,0.75) 0%,rgba(95,101,100,0.1) 80%,rgba(95,101,100,0) 100%);
        transition: background-color 150ms ease-in;

        &:before, &:after {
            content: '';
            width: 1000px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100%;
            background: linear-gradient(to bottom, rgba(95,101,100,0.75) 0%,rgba(95,101,100,0.1) 80%,rgba(95,101,100,0) 100%);
            transition: background-color 150ms ease-in;
        }

        .top-bar-center {
            align-items: center;
            background: transparent;
            border: 0;
            display: flex;
            justify-content: center;
            left: 50%;
            margin-left: -95px;
            position: absolute;
            top: 0;
            width: 190px;
            height: 56px;

            .logo {
                img {
                    display: block;
                    height: 32px;
                    margin-top: 5px;

                    &:first-child {
                        display: block;
                    }

                    &:last-child {
                        display: none;
                    }
                }
            }
        }

        .top-bar-left {
            background-color: transparent;
            border-bottom: 0;
            display: flex;
            flex-direction: row;
            margin-top: 0;
            order: 1;
            width: 100%;

            .toggle-menu {
                display: none;
            }

            > .menu {
                background: transparent;
                width: auto;
                display: none;
                border: 0;
                flex: 1 1 auto;

                > li {
                    display: inline-block;
                    width: auto;
                    padding: 1.375rem 0 0;
                    border: 0;

                    > a {
                        color: $white;

                        @include breakpoint(xxlarge) {
                            padding-left: $p/2;
                            padding-right: $p/2;
                        }
                    }

                    &.is-dropdown-submenu-parent {

                        > a:after {
                            display: none;
                        }

                        &.opens-left > .is-dropdown-submenu,
                        &.opens-right > .is-dropdown-submenu,
                        &.opens-inner > .is-dropdown-submenu {
                            left: 0;
                            top: 100%;
                            margin-top: 32px;
                            border: 1px solid $light-gray;

                            &:before {
                                content: '';
                                display: block;
                                border-left: 10px solid transparent;
                                border-right: 10px solid transparent;
                                border-bottom: 20px solid $rgb-white;
                                width: 0;
                                opacity: .5;
                                height: 0;
                                position: absolute;
                                left: 15%;
                                top: -20px;
                                margin-left: -10px;
                                z-index: 101;
                            }

                            &.is-dropdown-end:before {
                                left: auto;
                                right: 10px;
                            }

                            &.is-dropdown-end:after {
                                left: auto;
                                right: 9px;
                            }
                        }

                        .is-dropdown-submenu-parent > a:after {
                            display: none;
                        }
                    }

                    > .is-dropdown-submenu {
                        display: none;
                        width: 250px;
                        background: $white;
                        padding: 0;
                        position: absolute;
                        top: 0;
                        left: 100%;
                        z-index: 1;

                        &.js-dropdown-active {
                            display: block;
                        }

                        > .is-submenu-item {
                            border-top: 1px solid $light-gray;
                            page-break-inside: avoid;

                            &:first-child {
                                border-top: 0;
                            }

                            .is-dropdown-submenu {
                                width: auto;
                                padding: 0 0 0 10px;
                                margin: 0;
                                display: block;
                            }
                        }
                    }
                }
            }

            .nav-language {
                display: block;
                float: right;
                margin: 1.875rem 0 0 1.5rem;
                color: $white;
                order: 2;
                position: static;
                text-align: left;
            }

            .searchbox {
                display: inline-block;
                padding: 1.4rem 0px 0 24px;
                position: relative;
                width: auto;
                border: 0;
                order: 3;

                a {
                    display: inline-block;

                    &:before {
                        content: '\e91d';
                        font-family: icomoon;
                        font-weight: bold;
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        color: $white;
                    }
                }

                .searchbox-dropdown {
                    display: none;
                    position: absolute;
                    left: auto;
                    top: 100%;
                    width: 250px;
                    margin: 22px 0 0 -200px;
                    padding: 0.9375rem;
                    border: 1px solid $light-gray;

                    input[type="text"] {
                        margin-bottom: 0;
                    }

                    input[type="submit"] {
                        top: 0.9375rem;
                        right: 0.9375rem;
                    }

                    .glyphicon-search {
                        &:before {
                            top: 1rem;
                            right: 0.9375rem;
                        }
                    }

                    &:after {
                        content: '';
                        display: block;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 20px solid $rgb-white;
                        width: 0;
                        opacity: .5;
                        height: 0;
                        position: absolute;
                        left: 50%;
                        top: -20px;
                        margin-left: 72px;
                        z-index: 101;
                    }
                }
            }
        }

        &.is-sticky {
            background: $white;
            border-bottom: 1px solid $light-gray;
            transition: background-color 150ms ease-out;

            &:before, &:after {
                background: $white;
                transition: background-color 150ms ease-out;
                border-bottom: 1px solid $light-gray;
                bottom: -1px;
            }

            .logo img {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: block;
                }
            }

            .top-bar-left > .menu {
                > li a {
                    color: $black;
                }

                > li.is-dropdown-submenu-parent > a:after {
                    border-color: $black transparent transparent;
                }

                > li.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu:before {
                    border-bottom-color: $light-gray;
                }

                .header-button a:hover {
                    color: #30383B;
                }
            }

            .nav-language {
                color: $black;

                a {
                    color: inherit;
                }
            }

            .searchbox {
                a {
                    display: inline-block;

                    &:before {
                        color: $black;
                    }
                }
            }
        }
    }
}


/* header with video on homepage */ 
$wh-header-height-s: 260px;
$wh-header-height-m: 350px;
$wh-header-height: 600px;

header.header-home {
    background-color: #efefef;
    width: 100%;
    clear: both;
    height: $wh-header-height-m;
    position: relative;
    overflow: hidden;

    @include breakpoint(medium) {
        height: $wh-header-height-s;
    }

    @include breakpoint(768px) {
        height: $wh-header-height-m;
    }

    &:before {
        border-bottom: 880px solid rgba(255,255,255,.3);
        border-left: 336px solid transparent;
        border-right: 336px solid transparent;
        content: '';
        display: block;
        height: 0;
        left: 50%;
        margin-left: -336px;
        position: absolute;
        top: 0;
        width: 0;
        z-index: 10;
    }

    .video-viewport {
        position: absolute;
        top: 0;
        overflow: hidden;

        .mobile-image,
        .image {
            width: 100%;
            height: 100%;
            min-width: 60em;
            background-size: cover;
            background-position: center center;
        }

        .mobile-image {
            min-width: 320px;
        }

        video,
        .image {
            display: none;
        }
    }

    .headertitle {
        align-items: flex-start;
        height: $wh-header-height-m;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 10px;
        position: relative;
        z-index: 1;

        @include breakpoint(medium) {
            align-items: center;
            justify-content: center;
            height: $wh-header-height-s;
            width: 50%;

            h1, h2 {
                text-align: left;
            }
        }

        @include breakpoint(768px) {
            height: $wh-header-height-m;
        }

        h1, h2 {
            text-align: left;
            color: $white;
            line-height: 1;
            margin: 0 0 20px;
            font-size: 2.5em;
            text-shadow: 0px 0px 30px rgba(0,0,0,0.35);
            max-width: 500px;
        }
    }

    .headertitle__inner {
        padding-left: 20px;
        width: 80%;

        @include breakpoint(medium) {
            width: 50%;
            margin: 0 auto;
        }
    }

    .scroll-indicator {
        display: none;
        position: absolute;
        z-index: 10;
        bottom: 20px;
        left: 50%;
        border: 2px solid $white;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        margin-left: -30px;
        cursor: pointer;

        @include breakpoint(medium) {
            display: block;
        }

        &:before {
            content: '\e916';
            font-family: icomoon;
            font-size: 1.5rem;
            line-height: 57px;
            color: $white;
            width: 57px;
            text-align: center;
            display: inline-block;
            margin-top: 0;
            -webkit-animation: bouncing 5s infinite;
            animation: bouncing 5s infinite;
            -webkit-animation-timing-function: ease-in; /* Chrome, Safari, Opera */
            animation-timing-function: ease-in;
        }

        @-webkit-keyframes bouncing {
            0% {
                margin-top: 0;
            }

            3% {
                margin-top: 9px;
            }

            6% {
                margin-top: 5px
            }

            9% {
                margin-top: 11px
            }

            12% {
                margin-top: 7px
            }

            15% {
                margin-top: 11px
            }

            18% {
                margin-top: 0
            }

            100% {
                margin-top: 0;
            }
        }

        @keyframes bouncing {
            0% {
                margin-top: 0;
            }

            3% {
                margin-top: 9px;
            }

            6% {
                margin-top: 5px
            }

            9% {
                margin-top: 11px
            }

            12% {
                margin-top: 7px
            }

            15% {
                margin-top: 11px
            }

            18% {
                margin-top: 0
            }

            100% {
                margin-top: 0;
            }
        }
    }
}

@include breakpoint(large) {

    header.header-home {
        height: $wh-header-height;
        position: relative;

        .video-viewport {
            video,
            .image {
                display: block;
            }

            .mobile-image {
                display: none;
            }
        }

        .left-container {
            height: $wh-header-height;
        }

        .headertitle {
            height: $wh-header-height;
            width: 75%;

            h1 {
                font-size: 5.3125rem;
            }
        }

        .headertitle__inner {
            width: 80%;
        }

        .scroll-indicator {
            z-index: 100;
        }

        .play-video {
            display: none;
        }
    }
}

@include breakpoint(xxlarge) {
    header.header-home {

        .headertitle__inner {
            width: 66%;
        }

    }
}

header.header-page {
    overflow: hidden;
    position: relative;
    padding-top: 50px;
    margin-bottom: $m;
    background-position: 50%;
    height: $wh-header-height-m;

    @include breakpoint(medium) {
        height: $wh-header-height-s;
    }

    @include breakpoint(768px) {
        height: $wh-header-height-m;
    }

    &:before {
        border-bottom: 880px solid rgba(255,255,255,.4);
        border-left: 336px solid transparent;
        border-right: 336px solid transparent;
        content: '';
        display: block;
        height: 0;
        left: 50%;
        margin-left: -336px;
        position: absolute;
        top: 0;
        width: 0;
    }

    img {
        width: 100%;
        height: auto;
    }

    @include breakpoint(medium) {
        padding-top: 0;
        margin-bottom: $m*2;
    }


    @include breakpoint(large) {
        height: 380px;
        padding-top: 0;
        margin-bottom: $m*2;
    }

    @include breakpoint(xlarge) {
        height: 430px;
    }

    &.header-page-extended {

        .title-container {

            @include breakpoint(medium) {
                position: absolute;
                z-index: 1;
                top: 55px;
                left: 0;
                width: 50vw;
                height: calc(#{$wh-header-height-s} - 55);
            }

            @include breakpoint(768px) {
                height: calc(#{$wh-header-height-m} - 55);
            }

            .title-container-text {
                color: $white;
                padding: $p;

                h2 {
                    font-size: 3rem;

                    span {
                        color: $white;
                    }
                }

                h2, p {
                    color: inherit;
                    margin-bottom: 0;
                }

                p, a {
                    @include color-opacity($white, 0.75);
                    font-size: 0.9375rem;
                    line-height: 2;
                }

                a {
                    text-transform: uppercase;

                    &:after {
                        content: '\e917';
                        font-family: icomoon;
                        font-variant: normal;
                        margin-left: $m/2;
                    }

                    &:hover {
                        color: $white;
                    }
                }
            }
        }

        @include breakpoint(large) {
            height: 500px;

            .title-container {
                position: absolute;
                z-index: 1;
                top: 55px;
                left: 0;
                width: 50vw;
                height: 445px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        @include breakpoint(xlarge) {
            height: 500px;

            .title-container {
                height: 500px;
                top: 0;

                .title-container-text {
                    width: 50%;
                    padding: 0 0 0 10px;
                }
            }
        }
    }
}
