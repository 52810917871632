﻿.googlemapsblock {
    background: #eaeaea;
    margin-bottom: $m*2;
    clear: both;

    .row {
        padding-bottom: 0;
    }

    .image {
        text-align: center;
    }

}
