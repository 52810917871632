/*** Elements: Icons ***/

@include font-face('icomoon', '../fonts/icomoon', normal, normal, '?km2pt8', 'false');

.icon,
[class^='icon-'],
[class*=' icon-'] {
	@include icon-before();

	&.is-after {
		@include icon-after();
	}
}

@include icon-selector('arrow-down', '\2228'); // ∨
@include icon-selector('arrow-left', '\2039'); // ‹
@include icon-selector('arrow-right', '\203A'); // ›
@include icon-selector('arrow-up', '\2227'); // ∧
@include icon-selector('checkmark', '\221A'); // √
@include icon-selector('close', '\D7'); // ×
@include icon-selector('home', '\394'); // Δ
@include icon-selector('minus', '\2D'); // -
@include icon-selector('plus', '\2B'); // +
