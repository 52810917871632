﻿.teaserblock,
.doubleteaserblock {
    padding: 0 !important;
    clear: both;

    .row {
        padding-bottom: 0;
        overflow: hidden;

        &:first-child {
            .teaser {
                font-size: 1rem;
            }
        }
    }

    .teaser {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        min-height: 200px;
        color: $white;
        padding: $p;
        text-align: center;
        position: relative;
        font-size: 0.875rem;
        order: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include breakpoint(medium) {
            order: inherit;
        }

        h3 {
            color: inherit;
            font-size: 2rem;
            position: relative;
            max-width: 600px;
            margin: 0 auto $m;
            text-align: center;
        }

        &.teaser-purple {
            background: $wh-purple;
        }

        &.teaser-pink {
            background: $wh-pink;
        }

        &.teaser-orange {
            background: $wh-orange;
        }

        &.teaser-yellow {
            background: $wh-yellow;
        }

        &.teaser-green {
            background: $wh-green;
        }

        &.teaser-darkgreen {
            background: $wh-darkgreen;
        }

        &.teaser-blue {
            background: $wh-blue;
        }

        &.teaser-darkblue {
            background: $wh-darkblue;
        }

        &.teaser-lightgrey {
            background: $wh-lightgrey;
        }

        &.teaser-grey {
            background: $wh-grey;
        }

        &.teaser-darkgrey {
            background: $wh-darkgrey;
        }

        .teaser-text {
            font-size: 1.125rem;
            line-height: 1.4;
            position: relative;
            max-width: 600px;
            margin: 0 auto;
            padding-bottom: 20px;

            p {
                line-height: 1.4;
                margin-bottom: 0;
            }
        }
    }

    .teaser[style*="background-image"] {
        order: 0;

        @include breakpoint(medium) {
            order: inherit;
        }
    }

    @include breakpoint(large) {
        .row {
            height: 360px;
        }
    }

    .teaser-top {
        &:before {
            border-bottom: 880px solid rgba(255,255,255,.3);
            border-left: 336px solid transparent;
            border-right: 336px solid transparent;
            content: '';
            display: block;
            height: 0;
            left: 50%;
            margin-left: -336px;
            position: absolute;
            top: 0;
            width: 0;
            z-index: 1;
        }

    }
}

.doubleteaserblock {

    .row {
        &:first-child {
            height: 300px;

            @include breakpoint(large) {
                height: 360px;
            }
        }
    }

    .teaser-top[style*="background-image"] {
        height: 300px;

        @include breakpoint(large) {
            height: 360px;
        }
    }

    @include breakpoint(large) {

        .teaser-top {

            .teaser-text {
                max-width: 66.67%;
            }
        }
    }
}

.teaser--dark {
    position: relative;

    &:after {
        background: rgba(84,83,83,0.35);
        bottom: 0;
        content: '';
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    > * {
        position: relative;
        z-index: 1;
    }
}

.teaserblock {

    h3 {
        text-align: center;
        position: relative;
    }

    @include breakpoint(large) {
        .row,
        .row:first-child {
            height: 360px;
        }

        .teaser {
            padding: 0 $p*2.5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .teaser-image {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            & + .teaser {
                padding: 2rem;
            }
        }
    }
}

.doubleteaserblock {
    @include breakpoint(large) {
        .row {
            height: 255px;
        }

        .row:first-child {
            height: 360px;
        }
    }
}

.generaloverviewblock {
    .teaserblock,
    .doubleteaserblock {
        padding-left: 0.9375rem !important;
        padding-right: 0.9375rem !important;
    }
}
