/*** Elements: States ***/

/* stylelint-disable declaration-no-important */

.is-hidden {
	display: none !important;
}

.is-visually-hidden {
	@include visually-hidden();
}

.is-measurable {
	visibility: hidden !important;
	display: block !important;
	position: absolute !important;
	left: -999em !important;
	width: auto !important;
	height: auto !important;
}

.has-medium-weight {
	font-weight: $fw-medium;
}

.has-bold-weight {
	font-weight: $fw-bold;
}
