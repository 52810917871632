/*** Mixins ***/

/*
* Clearfix to clear nested floating columns:
*/

@mixin clearfix() {
	&::before,
	&::after {
		content: ' ';
		display: table;
	}

	&::after {
		clear: both;
	}
}


/*
* Browser specific anti-aliasing styles.
*/

@mixin antialiased() {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}


/*
* Add styles to only visually hide an element but maintain the accessibility.
*/

@mixin visually-hidden() {
	overflow: hidden;
	clip: rect(0 0 0 0);
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

/*
* Convert a hex color to rgba background
*/

@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; 
    background: rgba($color, $opacity);
}

/*
* Convert a hex color to rgba border-left
*/

@mixin borderleft-opacity($color, $borderw, $opacity: 0.3) {
    border-left: $borderw solid $color; 
    border-left: $borderw solid rgba($color, $opacity);
}

/*
* Convert a hex color to rgba
*/

@mixin color-opacity($color, $opacity: 0.3) {
    color: $color; 
    color: rgba($color, $opacity);
}

/*
* Centered position by first centering the origin and then offsetting its contents.
*/

@mixin centered() {
	position: absolute;
	top: 50%;
	left: 50%;

	@include translate(-50%, -50%);
}


/*
* Fontface mixin to include local or external fonts.
* @param string  : font family name
* @param string  : path and filename to font
* @param string  : font-weight (defaults to normal)
* @param string  : font-style (defaults to normal)
* @param string  : (optional) unique identifier to prevent cache
* @param string  : (optional) boolean to prevent woff2 file from being requested if unavailable
*/

@mixin font-face($font-family, $path, $weight: normal, $style: normal, $identifier: '', $woff2available: 'true') {
	@font-face {
		font-family: '#{$font-family}';

		@if $woff2available == 'true' {
			src: url('#{$path}.woff2#{$identifier}') format('woff2'),
				url('#{$path}.woff#{$identifier}') format('woff');
		} @else {
			src: url('#{$path}.woff#{$identifier}') format('woff');
		}
		font-weight: $weight;
		font-style: $style;
	}
}


/*
* Set font-size with (default) line-height.
* @param string  : font size
* @param string  : (optional) line height
*/

@mixin font-size($font-size, $line-height: $lh) {
	line-height: $line-height;
	font-size: $font-size;
}


/*
* Icon pseudo styles.
*/

@mixin icon-pseudo() {
	@include antialiased();
	display: inline-block;
	vertical-align: text-top;
	speak: none;
	font-family: icomoon;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
}


/*
* Icon before declaration.
*/

@mixin icon-before() {
	position: relative;

	&::before {
		@include icon-pseudo();
	}

	&::after {
		display: none;
	}
}


/*
* Icon after declaration.
*/

@mixin icon-after() {
	position: relative;

	&::before {
		display: none;
	}

	&::after {
		@include icon-pseudo();
	}
}


/*
* Generates CSS selectors for icon characters.
* @param string  : icon name
* @param string  : unicode character
*/

@mixin icon-selector($icon-name, $unicode) {
	.icon-#{$icon-name} {
		&::before,
		&::after {
			content: $unicode;
		}
	}
}


/*
 * Shows current responsive view in top-right corner for debugging purposes.
 */

@mixin viewport-indicators() {
	&::before {
		content: 'S';
		opacity: 0.25;
		z-index: 999;
		position: fixed;
		top: $p/2;
		right: $p/2;
		color: $black;

		@include breakpoint(medium) {
			content: 'M';
		}

		@include breakpoint(large) {
			content: 'L';
		}

		@include breakpoint(xlarge) {
			content: 'XL';
		}

		@include breakpoint(xxlarge) {
			content: 'XXL';
		}
	}
}
