/* Page footer */

footer {
    background: $white;
    padding: $p 0 0;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 0;
        margin-bottom: 0;
        list-style: none outside;

        li {
            margin: 0 $m/2;

            a {
                color: $black;

                &:hover {
                    color: $black;
                }
            }
        }
    }

    ul.links {
        font-size: .875rem;
        margin-bottom: 1.5rem;

        li {
            flex: 0 0 auto;
        }
    }

    ul.social, ul.disclaimer, ul.disclaimer-links {
        li {
            line-height: 26px;
        }
    }

    ul.social {
        img {
            border-radius: 8px; 
            height: 26px;
            width: 26px;
        }
    }

    ul.disclaimer {
        color: #B9B9B9;
        font-family: $ff-bold;
        font-size: 0.75rem;
        font-weight: bold;
        text-transform: none;
    }

    ul.disclaimer-links {
        font-size: 0.75rem;
        font-family: $ff-bold;
        font-weight: bold;
        text-transform: none;

        a {
            color: #B9B9B9;
        }
    }
}
.footer__center {
    display: flex;
    flex-direction: column;

    @include breakpoint(large) {
        flex-direction: row;
        justify-content: center;
    }
}

.footer__title {
    color: $text-color;
    font-size: 28px;
    margin-bottom: .625rem;
    text-align: center;
}
