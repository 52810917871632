﻿.cookie-bar {
    position: fixed;
    bottom: 0;
    
    padding: $p / 2;
    width: 100%;
    display: none;
    
    background-color: $black;
    color: $white;
    font-size: $fs-s;
    font-weight: $fw-thin;
    
    text-align: center;
    line-height: $lh;

    
    &--visible {
        z-index: 999;
        
        display: block;
    }

    &__header {
        font-size: $fs-h-s;
        font-weight: $fw-light;
    }

    &__link {
        display: inline-block;
        padding-right: $p / 2;

        color: $link-color;

        &:last-of-type {
            padding: 0;
        }
    }
}

.cookie-consent {
    padding: $p;
    
    &__header {
        margin-bottom: $m / 2;
        
        font-size: $fs-h-m;
        line-height: $lh-l;
        
        color: $wh-purple;
    }
    
    &__text {
        margin-bottom: $m;

        font-size: $fs-s;
        font-weight: $fw-thin;
        line-height: $lh;

        color: $black;
    }

    input[type='checkbox'] {
        width: 15px;
        height: 15px;

        outline: 0;
    }

    .slider__label {
        line-height: $lh-l;

        color: $dark-gray;

        cursor: pointer;

        &--disabled {
            color: $disabled-color;
            
            cursor: auto;
            
            input {
                opacity: .4;
            }

            span {
                color: $disabled-color;
            }
        }

        &:last-of-type {
            margin-bottom: $m;
        }

        span {
            font-weight: $fw-bold;

            &:after {
                content: ': ';
            }
        }
    }
}

.cookie-notifcation-box {
    position: relative;
    z-index: 300;
    padding: 30px;
    border-color: $wh-purple;
    border-width: 2px;
    border-style: solid;
    text-align: center;
}